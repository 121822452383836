// import "../Styles/users.scss";
import NewPost from "./NewPost";
import Header1 from "../Util/Header1";
import Navigation from "../Util/Navigation";
import "../../Styles/singlepost.scss";

import React, { useEffect, useState } from "react";
import EditNews from "./EditNews";
import DeleteNews from "./DeleteNews";

export default function NewsSingleInstance(props) {
  const [data, setData] = useState(null);
  const [toggleEditNews, setToggleEditNews] = useState(false);
  const [toggleDeleteNews, setToggleDeleteNews] = useState(false);
  const [refresh, setRefresh] = useState();
  const [blob, setBlob] = useState(null);

  useEffect(() => {
    let url = window.location.href.split("/")[4];
    fetch(`/api/news/${url}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    if (data?.Image) {
      const url = data?.Image.substring(8, data?.Image.length);
      fetch(`/api/uploads/${url}`)
        .then((res) => {
          console.log(res);
          if (res.ok) return res.blob();
          else throw Error("");
        })
        .then((blob) => {
          const src = URL.createObjectURL(blob);
          console.log(src);
          setBlob(src);
        })
        .catch((e) => {});
    }
  }, [data?.Image]);

  return (
    <div className="news">
      {data && (
        <div className="singleInstance">
          {toggleEditNews && (
            <EditNews
              setToggleEditNews={setToggleEditNews}
              data={data}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}
          {toggleDeleteNews && (
            <DeleteNews
              setToggleDeleteNews={setToggleDeleteNews}
              data={data}
              setRefresh={setRefresh}
            />
          )}
          <h6>
            {data.createdAt.split("T")[0]} :{" "}
            {data.createdAt.split("T")[1].split(".")[0].substring(0, 5)}
            hrs &nbsp; &nbsp;
            <i
              className="fa fa-edit"
              title="Click to edit this news"
              onClick={() => {
                setToggleEditNews(!toggleEditNews);
              }}
            ></i>
            &nbsp; &nbsp;
            <i
              className="fa fa-trash"
              title="Click to delete this news"
              onClick={() => {
                setToggleDeleteNews(!toggleDeleteNews);
              }}
            ></i>
          </h6>
          <h3>{data.Title}</h3>
          <h5>Category: {data.Type}</h5>
          <img src={blob} alt={data.Title} />
          <p>Details: {data.Description}</p>
          <a href={"https://" + data.Link} target="_blank">
            Click for more information...
          </a>
        </div>
      )}
    </div>
  );
}
