import "../../Styles/news.scss";
import { useEffect, useState } from "react";
import BlogPost from "../News/BlogPost";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function NewsBlog(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [offset, setOffset] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [newsDisplay, setnewsDisplay] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/news/paginated/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [offset]);

  function quickSearch(q) {
    setData(null);
    setLoading(true);
    fetch(`/api/news/quicksearch/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setnewsDisplay(data.data[0].newsDisplay);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="news">
      <div className="list">
        <div className="utp">
          <h3>News Blog</h3>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search..."
              onChange={(e) => {
                const v = e.target.value;
                if (v !== "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>          
        </div>
        {data &&
          data?.results?.length > 0 &&
          data?.results.map((item) => {
            return <BlogPost item={item} />;
          })}
        {loading && <WaveLoading />}
        {data && (
          <Pagination total={data?.total} setOffset={setOffset} page={offset} />
        )}
        {clicked && <Dropopt />}
      </div>
    </div>
  );
}

const Dropopt = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [gbv, setGbv] = useState(null);
  const [me, setMe] = useState(null);

  function ByGBV() {
    setData(null);
    setLoading(true);
    fetch(`/news/type/GBV`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setGbv(data.data[0].gbv);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function ByME() {
    setData(null);
    setLoading(true);
    fetch(`/news/type/ME`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setMe(data.data[0].me);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <div className="dropoptions">
      <div className="optbox">
        <p>Sort by Name</p>
        <p>Sort by GBV</p>
        <p>Sort by ME</p>
      </div>
    </div>
  );
};
