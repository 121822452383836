import { useEffect, useState, useRef, useLayoutEffect } from "react";
import CustomLineChart from "../Stats/CustomLineChart";
import CustomPieChart from "../Stats/CustomPieChart";
import CustomBarChart from "../Stats/CustomBarChart";
import Pagination from "../Util/Pagination";
// import Chart from "./Chart";
import OngoingTable from "./OngoingTable";
import PendingTable from "./PendingTable";

export default function Rides(props) {
  const [padding, setPadding] = useState([100, 700, 100, 100]);
  const [rStatus, setRStatus] = useState(null);
  const [expand, setExpand] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [drop, setDrop] = useState(false);
  const [eventId, setEventId] = useState(null);
  const colors = ["red", "orange", "blue", "purple", "black"];
  const [erteams, setErteams] = useState();
  const [performanceStats, setPerformanceStats] = useState(null);

  function scrollPages(offset) {
    props.setOffset(offset);
  }

  return (
    <div>
      <div
        className={expand ? "expand_hidden" : "expand"}
        onClick={() => {
          setExpand(true);
        }}
      >
        <i className="fa fa-expand"></i>
      </div>
      {expand && (
        <div className={"r_analysis"}>
          <OngoingTable setExpand={setExpand} setSelected={props.setSelected} />
          <PendingTable
            setData={props.setData}
            setExpand={setExpand}
            setSelected={props.setSelected}
          />
        </div>
      )}
    </div>
  );
}
