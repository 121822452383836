import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import TPagination from "./TPagination";

export default function OngoingTable(props) {

  const [offset, setOffset] = useState(0)
  const [data, setData] = useState(0);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    fetch(`/api/rides/status/In Progress/${offset}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data.result);
        setTotal(data.total);
      })
      .catch((e) => {});
  }, [offset]);

  function scrollPages(v) {
    props.setOffset(v);
  }

  return (
    <div className="top_table">
      <div className="title">
        <h3>Rides in Progress</h3>
        <i
          onClick={() => props.setExpand(false)}
          className="fa fa-window-minimize"
        ></i>
      </div>
      <div className="table">
        <div className="bhead">
          <h4>SN</h4>
          <h4>ERName</h4>
          <h4>Customer</h4>
          <h4>Start</h4>
          <h4>Reported</h4>
        </div>
        <div className="brow">
          {data &&
            data.map((item, index) => {
              return (
                <Item
                  key={index}
                  index={index}
                  sn={props.offset + index + 1}
                  item={item}
                  total={100}
                  offset={props.offset}
                  setSelected = {props.setSelected}
                />
              );
            })}
        </div>
      </div>
      <div className="footer">
        <TPagination
          offset={offset}
          setOffset={setOffset}
          total={total}
          page={offset}
          scrollPages={scrollPages}
        />
        <p>
          Showing {props.offset + 1} -{" "}
          {props.offset + 5 < props.total ? props.offset + 5 : props.total} of{" "}
          {props.total} Incident Reports
        </p>
      </div>
    </div>
  );
}

const Item = (props) => {
  const dropdown = props.drop ? "dropdown" : "noDropdown";
  const [rStatus, setRStatus] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const popup = "showPopUp";
  const [erteams, setERTeams] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [err, setErr] = useState(null);
  const statuses = ["Recieved", "In Progress", "Resolved", "Not Resolved"];
  const [color, setColor] = useState("#60606005");
  const rfselectERTeam = useRef();

  useEffect(() => {
    if (props.index % 2 == 0) {
      setColor("#60606010");
    }
  }, []);

  const getERTeams = () => {
    // setDrop(!drop);
    fetch(`/api/erteams`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setERTeams(data);
        props.setDrop(!props.drop);
      })
      .catch((err) => {});
  };

  return (
    <div style={{ backgroundColor: color }} className="row" onClick = {() => {props.setSelected(props.item.RideID)}}>
      <p>{props.sn}</p>
      <p>{props.item.ERName}</p>
      <p>{props.item.UserName}</p>
      <p>{props.item?.StartDate}</p>
      <p>
        {props.item?.createdAt.split("T")[1].substr(0, 5) +
          "  " +
          props.item?.createdAt.split("T")[0].replaceAll("-", "/")}
      </p>
    </div>
  );
};
