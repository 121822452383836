


export default function RightPanel(props) {

  return (
    <div className="right_panel">

      {/* <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        pagination={true}
        modules={[Pagination]}
        className="mySwiper"
      >
        {content.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={item} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper> */}
    </div>
  );
}
