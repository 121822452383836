import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({
  component: Component,
  ...restOfProps
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkn");
    if (token) {
      try {
        let decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIsAuthenticated(false);
        localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
        window.location.href = "/login";
      })
      .catch((error) => {
        window.location.href = "/login";
      });
  };

  return isAuthenticated ? (
    <Component {...restOfProps} />
  ) : (
    <Navigate to="/login" replace />
  );
}

