import MyMap from "../Map/Map";
import { useEffect, useState, useRef } from "react";
import "../../Styles/map.scss";
import Header1 from "../Util/Header1";
import Navigation from "../Util/Navigation";
import MapEM from "../Map/MapEM";
import Analysis from "../IncidenceManagement/Analysis";

export default function EMMap(props) {

  const [data, setData] = useState(null);
  const [tdata, setTData] = useState(null);
  const [total, setTotal] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch(`/api/reports/type/ME/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setTData(data.results);
        setTotal(data.total);
      })
      .catch((e) => {});
  }, [offset]);

  return (
    <div className="gis">
      <div className="bdy">
        <div className="map">
          <MapEM setData={setData} />
        </div>
        <Analysis
          type="ME"
          data={tdata}
          total={total}
          offset={offset}
          setOffset={setOffset}
        />
      </div>
    </div>
  );
}
