export default function TPagination(props) {
  const total = Math.ceil(props?.total / 5.0);

  const prevPage = () => {
    if (props.page !== 0) {
      props.setOffset(props.page - 5);
    }
  };

  const nextPage = () => {
    if (props.page + 5 < total * 5) {
      props.setOffset(props.page + 5);
    }
  };

  return (
    <div className="pagination">
      <i
        className="fa fa-arrow-left"
        onClick={() => {
          prevPage();
        }}
      ></i>
      <p>
        {props.page ? props.page / 5 + 1 : 1} / {total ? total : 1}
      </p>
      <i
        className="fa fa-arrow-right"
        onClick={() => {
          nextPage();
        }}
      ></i>
    </div>
  );
}
