import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

export default function StackedAreaChart(props) {
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <ResponsiveContainer width={"100%"} aspect={props.aspect}>
      <AreaChart
        width={"100%"}
        height={"100%"}
        data={props.data}
        margin={{ left: -25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          style={{ fill: "#29B6F6" }}
          tick={{ fill: "red" }}
          fontSize={10}
          tickLine={{ stroke: "#29B6F6" }}
        />
        <YAxis
          fontSize={10}
          style={{ fill: "#29B6F6" }}
          tick={{ fill: "red" }}
          tickLine={{ stroke: "#29B6F6" }}
        />
        <Tooltip />
        {/* <Legend style={{ fontSize: 7 }} /> */}
        {props.unique &&
          props.unique.map((item, index) => {
            let c = getRandomColor();
            return (
              <Area
                key={index}
                type="monotone"
                dataKey={item}
                stackId={index}
                stroke={c}
                fill={c}
              />
            );
          })}
      </AreaChart>
    </ResponsiveContainer>
  );
}
