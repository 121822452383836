import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import "../../Styles/data.scss";
import { isMobile } from "react-device-detect";
import WaveLoading from "../Util/WaveLoading";
import { FaSpinner } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { RiUserReceived2Fill } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
import {jsPDF } from "jspdf";
import "jspdf-autotable";

export default function DataPage(props) {
  const [loading, setLoading] = useState(false);
  const [head, setHead] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showing, setShowing] = useState(null);
  const [editing, setEditing] = useState(null);
  const [column, setColumn] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/reports/paginated/${offset}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data?.results.length > 0) {
          const cols = Object.keys(data?.results[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "longitude" &&
              item.toLowerCase() !== "latitude" &&
              item.toLowerCase() !== "erteamid" &&
              item.toLowerCase() !== "er_id" &&
              item.toLowerCase() !== "action" &&
              item.toLowerCase() !== "category" &&
              item.toLowerCase() !== "description" &&
              item.toLowerCase() !== "time" &&
              item.toLowerCase() !== "updatedat" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [offset, props.filter, refresh]);

  async function quickSearch (v) {
    await fetch(`/api/reports/search/${column}/${v}/${offset}`, {
      method: "GET",
    })
    .then((res) => {
      if (res.ok) return res.json();
      else throw Error("");
    })
    .then((data) => {
      setData(data);
    })
    .catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="data">
      <div className="topbar">
        <h4>Reports Data</h4>
      </div>
      
      <div className="list">
        <div className="search">
          <select
            onChange={(e) => {
              if (e.target.value != "") {
                setColumn(e.target.value);
              }
            }}
          >
            <option value="">Select Column</option>
            <option value="Phone">Phone</option>
          </select>
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Search..."
            onChange={(e) => {
              const v = e.target.value;
              if (v.length > 0) {
                quickSearch(v);
              }
              if (v.length === 0) {
                setRefresh(!refresh);
              }
            }}
          />
          <FontAwesomeIcon className="fa-search" icon={faSearch} />
        </div>
        <div className="head">
          <h4>Client Details</h4>
          <h4>Type</h4>
          <h4>Status</h4>
          <h4>Responder</h4>
        </div>
        {data &&
          data.results.length > 0 &&
          data.results.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
              />
            );
          })}

        {data?.total && (
          <Pagination total={data?.total} setOffset={setOffset} page={offset} />
        )}

        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.results}
            url={props.url}
          />
        )}
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606005";

  return (
    <div className="item">
      <div
        style={{
          backgroundColor: cl,
        }}
        className="row"
        onClick={() => {
          props.setShowing(props.index);
        }}
      >
        <div>
          <h5>{props?.data?.Name}</h5>
          <p>&nbsp;&nbsp;&nbsp;{props?.data?.Phone}</p>
        </div>
        <div>
          <h5>
            {props?.data?.Type}
          </h5>
          {/* <p>{props?.data?.Category}</p> */}
        </div>
        <div>
          <p>
            {props?.data?.Status === "Received" && (
              <span className="purple"><RiUserReceived2Fill />&nbsp;&nbsp;{props?.data?.Status}</span>
            )}
            {props?.data?.Status === "Resolved" && (
              <span className="green">
                {/* checkmark icon */}
                <i className="fa fa-check"></i>&nbsp;&nbsp;
                {props?.data?.Status}
              </span>
            )}
            {props?.data?.Status === "In Progress" && (
              <span className="yellow"><FaSpinner />&nbsp;&nbsp;{props?.data?.Status}</span>
            )}
            {props?.data?.Status === "Not Resolved" && (
              <span className="red">
                <MdCancel />&nbsp;&nbsp;
                {props?.data?.Status}
              </span>
            )}
          </p>
        </div>
        <div>
          <h5>{props?.data?.ERName ? props?.data?.ERName : "Not Assigned"}</h5>
        </div>        
      </div>
    </div>
  );
};

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);

  useEffect(() => {
    if (props.showing !== null) {
      const cols = Object.keys(props.data[props.showing]);
      let d = [];
      cols.forEach((item) => {
        if (item.toLowerCase() !== "geom" && !item.includes("ID")) {
          d.push(item);
        }
      });
      setCols(d);
    }
  }, [props.showing]);

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const data = props.data[props.showing];

    // Set up the table for PDF
    const tableData = [];
    cols.forEach((item) => {
      if (data[item] !== undefined) {
        tableData.push([item, data[item]]);
      }
    });

    // Add content to PDF
    doc.text(props.data[props.showing]?.Name, 20, 10);
    doc.autoTable({ body: tableData });

    // Save PDF
    doc.save('report.pdf');
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <hr />

        <h6 onClick={handleDownloadPDF}><FontAwesomeIcon icon={faDownload} /> Download Report</h6>
        <div className="content">
          <div className="div2equal">
            {cols &&
              cols.map((item, i) => {
                return (
                  <p key={i}>
                    <b>{item}</b> {props.data[props.showing][item]}
                  </p>
                );
              })}
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
};
