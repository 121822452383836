import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";

export default function PendingTable(props) {
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`/api/rides/status/Resolved/${offset}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setData(data.result);
        setTotal(data.total);
      })
      .catch((e) => {});
  }, [offset]);

  function scrollPages(v) {
    setOffset(v);
  }

  return (
    <div className="bottom_table">
      <h3>Pending Rides</h3>
      <div className="table">
        <div className="bhead">
          <h4>SN</h4>
          <h4>ERName</h4>
          <h4>Customer</h4>
          <h4>Start</h4>
          <h4>Reported</h4>
        </div>
        <div className="brow">
          {data &&
            data.map((item, index) => {
              return (
                <Item key={index} index={index + 1} item={item} total={100} setSelected={props.setSelected}/>
              );
            })}
        </div>
      </div>
      <div className="footer">
        <Pagination
          offset={offset}
          setOffset={setOffset}
          total={total}
          page={offset}
          scrollPages={scrollPages}
        />
        <div>
          Showing {offset + 1} - {offset + 5 < total ? offset + 5 : total} of{" "}
          {total} Incident Reports
        </div>
      </div>
    </div>
  );
}

const Item = (props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth((props.item.TotalResponses / props.total) * 100);
  }, []);

  return (
    <div className="row" onClick = {() => {props.setSelected(props.item.RideID)}} >
      <p>{props.sn}</p> 
      <p>{props.item.ERName}</p>
      <p>{props.item.UserName}</p>
      <p>{props.item?.StartDate}</p>
      <p>
        {props.item?.createdAt.split("T")[1].substr(0, 5) +
          "  " +
          props.item?.createdAt.split("T")[0].replaceAll("-", "/")}
      </p>
    </div>
  );
};
