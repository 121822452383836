import MyMap from "../Home/Map";
import "../../Styles/gis.scss";
import Header1 from "../Util/Header1";
import Navigation from "../Util/Navigation";

export default function LandingMap(props) {
  return (
    <div className="gis">
        <div className="bdy">
          <div className="map">
            <MyMap />
          </div>
      </div>
    </div>
  );
}
