import { useLayoutEffect, useRef, useState } from "react";
import MapPieChart from "../Stats/CustomPieChart";
import MapBarChart from "./BarChart";

export default function Chart(props) {
  const [aspect, setAspect] = useState(2.1);
  const refPie1 = useRef(null);

  useLayoutEffect(() => {
    let d = (
      refPie1.current.offsetWidth / refPie1.current.offsetHeight
    ).toFixed(1);
    setAspect(d);
  }, []);

  return (
    <div ref={refPie1} className="r_chart">
      <div className="title">{props.title}</div>
      <div className="r_content">
        {props.data && props.title === "Incidences Status" && (
          <MapPieChart
            data={props.data}
            colors={props.colors}
            aspect={aspect}
            legend={props.legend}
          />
        )}
        {props.data && props.title === "ER Teams Performance" && (
          <MapBarChart data={props.data} colors={props.colors} legend={true} />
        )}
      </div>
    </div>
  );
}
