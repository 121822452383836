import MyMap from "../Home/Map";
import "../../Styles/gis.scss";
import Header1 from "../Util/Header1";
import Navigation from "../Util/Navigation";

import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Analysis from "../IncidenceManagement/Analysis";
import Rides from "../Rides/Rides";
import RidesMap from "../Rides/RidesMap";

const API_KEY = "AIzaSyBbEGhViFyDdJJcfl0Mgpv293jyNgTl364";

const contacts = [
  { name: "Spiderman", lat: -1.304195, lng: 36.838452 },
  { name: "Iron Man", lat: -1.294195, lng: 36.828452 },
  { name: "Hulk", lat: -1.284195, lng: 36.818452 },
];

const MarkersC = ({ text }) => <div className="contact">{text}</div>;

// const Map = ({ location, zoomLevel }) => (
//   <GoogleMapReact
//     bootstrapURLKeys={{ key: API_KEY }}
//     defaultCenter={{
//       lng: 36.828452,
//       lat: -1.294195,
//     }}
//     defaultZoom={12}
//   >
//     {contacts.map((contact, i) => (
//       <MarkersC
//         lat={contact.lat}
//         lng={contact.lng}
//         text={contact.name}
//         key={i}
//       />
//     ))}
//   </GoogleMapReact>
// );

export default function InProgressMap(props) {

  const [ridesInProgress, setRidesInProgress] = useState(null)
  const [ridesResolved, setRidesResolved] = useState(null)
  const [totalInProgress, setTotalInProgress] = useState(null)
  const [totalResolved, setTotalResolved] = useState(null)
  const [selected, setSelected] = useState(null)
  const [origin, setOrigin] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [currentLocation, setCurrentLocation] = React.useState(null);

  useEffect (() => {
    if (selected) {
      setOrigin(null)
      setDestination(null)
      fetch(`/api/rides/${selected}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          let origin = {}
          origin.lat = parseFloat(data.StartnEndCoordinates[0][0])
          origin.lng = parseFloat(data.StartnEndCoordinates[0][1])
          let destination = {}
          destination.lat = parseFloat(data.StartnEndCoordinates[1][0])
          destination.lng = parseFloat(data.StartnEndCoordinates[1][1])
          let currentLocation = {}
          currentLocation.lat = parseFloat(data.CurrentCoordinates[0])
          currentLocation.lng = parseFloat(data.CurrentCoordinates[1])
          setOrigin(origin)
          setDestination(destination)
          setCurrentLocation(currentLocation)
        })
        .catch((e) => {});
      }
    else {
      fetch(`/api/rides/paginated/0`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data.result[0]);
          let ls = []
          let origin = {}
          origin.lat = parseFloat(data.result[0].StartnEndCoordinates[0][0])
          origin.lng = parseFloat(data.result[0].StartnEndCoordinates[0][1])
          let destination = {}
          destination.lat = parseFloat(data.result[0].StartnEndCoordinates[1][0])
          destination.lng = parseFloat(data.result[0].StartnEndCoordinates[1][1])
          let currentLocation = {};
          currentLocation.lat = parseFloat(data.result[0].CurrentCoordinates[0]);
          currentLocation.lng = parseFloat(data.result[0].CurrentCoordinates[1]);
          setOrigin(origin)
          setDestination(destination)
          setCurrentLocation(currentLocation)
        })
        .catch((e) => {});
    }
  },[selected])

  const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 37.42216,
    lng: -122.08427,
  };

  return (
    <div className="gis">
      <div className="bdy">
        <div className="map">
          <RidesMap origin={origin} destination={destination} currentLocation={currentLocation}/>
        </div>
        <Rides
          ridesInProgress = {ridesInProgress}
          ridesResolved = {ridesResolved}
          totalInProgress = {totalInProgress}
          totalResolved = {totalResolved}
          setSelected = {setSelected}
        />
      </div>
    </div>
  );
}
