import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import Settings from "../components/Settings/Settings";
import DataPage from "../components/DataPage/DataPage";
import Landing from "../components/Home/Landing";
import UserHome from "../components/UsersF/UserHome";
import NewsHome from "../components/News/NewsHome.jsx";
import NewsSingleInstance from "../components/News/NewsSingleInstance.jsx";
import MapsHome from "../components/Map/MapsHome.jsx";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Landing showing={showing} />}

            {pathname[1] === "maps" && <MapsHome showing={showing} />}

            {pathname[1] === "users" && <UserHome showing={showing} />}

            {pathname[1] === "news" && 
              pathname[2] === "list" &&
              <NewsHome showing={showing} />
            }
            {pathname[1] === "news" &&
              pathname[2] != "" &&
              <NewsSingleInstance showing={showing} />
            }

            {pathname[1] === "data" && <DataPage showing={showing} />}

            {pathname[1] === "settings" && <Settings showing={showing} />}
          </div>
        </div>
      </div>
    </div>
  );
}
