import React, { useState } from "react";
import "../../Styles/userhome.scss";
import NewsBlog from "./NewsBlog";
import NewPost from "./NewPost";

export default function NewsHome(props) {
  const [active, setActive] = useState("News Blog");

  return (
    <div className="userhome">
      <div className="vtop">
        <div className="container">
          <Item txt="News Blog" active={active} setActive={setActive} />
          <Item txt="New Post" active={active} setActive={setActive} />
        </div>
      </div>

      <div>
        {active === "News Blog" && <NewsBlog />}
        {active === "New Post" && <NewPost />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <h4>{props.txt}</h4>
    </div>
  );
};
