import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import TPagination from "./TPagination";

export default function TopTable(props) {
  useEffect(() => {
    fetch(`/api/erteams/performance/${props.offset}`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        // props.setData(data);
        props.setTotal(data.length);
      })
      .catch((err) => {});
  }, [props.offset]);

  function scrollPages(v) {
    props.setOffset(v);
  }

  return (
    <div className="top_table">
      <div className="title">
        <h3>Incident Reports</h3>
        <i
          onClick={() => props.setExpand(false)}
          className="fa fa-window-minimize"
        ></i>
      </div>
      <div className="table">
        <div className="bhead">
          <h4>SN</h4>
          <h4>Phone</h4>
          <h4>Status</h4>
          <h4>Tasked to</h4>
          <h4>Reported</h4>
        </div>
        <div className="brow">
          {/* {console.log(props.data)} */}
          {props.data &&
            props.data.map((item, index) => {
              return (
                <Item
                  key={index}
                  index={index}
                  sn={props.offset + index + 1}
                  item={item}
                  total={100}
                  offset={props.offset}
                />
              );
            })}
        </div>
      </div>
      <div className="footer">
        <TPagination
          offset={props.offset}
          setOffset={props.setOffset}
          total={props.total}
          page={props.offset}
          scrollPages={scrollPages}
        />
        <p>
          Showing {props.offset + 1} -{" "}
          {props.offset + 5 < props.total ? props.offset + 5 : props.total} of{" "}
          {props.total} Incident Reports
        </p>
      </div>
    </div>
  );
}

const Item = (props) => {
  const dropdown = props.drop ? "dropdown" : "noDropdown";
  const [rStatus, setRStatus] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const popup = "showPopUp";
  const [erteams, setERTeams] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [err, setErr] = useState(null);
  const statuses = ["Recieved", "In Progress", "Resolved", "Not Resolved"];
  const [color, setColor] = useState("#60606005");
  const rfselectERTeam = useRef();

  useEffect(() => {
    if (props.index % 2 == 0) {
      setColor("#60606010");
    }
  }, []);

  const updateStatus = (reportId, erId, status) => {
    setIsLoading(true);
    const body = erId
      ? {
          ER_ID: erId,
          Status: status,
        }
      : {
          Status: status,
        };

    //  setDrop(!drop);
    fetch(`/api/reports/${reportId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.success) {
          setErr(data?.success);
          setTimeout(() => {
            setSelectedStatus("");
            setERTeams(null);
          }, 1000);
        } else setErr(data?.error);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getERTeams = () => {
    // setDrop(!drop);
    fetch(`/api/erteams`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setERTeams(data);
        props.setDrop(!props.drop);
      })
      .catch((err) => {});
  };

  return (
    <div style={{ backgroundColor: color }} className="row">
      <p>{props.sn}</p>
      <p>{props.item.Phone}</p>
      {props.item && (
        <select
          name=""
          id=""
          defaultValue={props.item.Status}
          onChange={(e) => {
            if (e.target.value === "In Progress") {
              getERTeams();
            }
            setSelectedStatus(e.target.value);
            setRStatus(true);
          }}
        >
          {statuses.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      )}
      <p>{props.item?.ERName ? props.item?.ERName : "Not Assigned"}</p>
      <p>
        {props.item?.createdAt.split("T")[1].substr(0, 5) +
          "  " +
          props.item?.createdAt.split("T")[0].replaceAll("-", "/")}
      </p>
      {selectedStatus !== null && (
        <>
          {selectedStatus === "In Progress" && erteams && (
            <div className={popup}>
              <div className="conf">
                <h3>Incident Status</h3>
                <br></br>
                <p>{err}</p>
                <br></br>
                <p>Select EMT</p>
                <br></br>
                <select
                  className="erteams"
                  name=""
                  id=""
                  defaultValue=""
                  ref={rfselectERTeam}
                  onChange={(e) => {}}
                >
                  <option value="none" selected disabled>
                    Select an ER.Team
                  </option>
                  {erteams.map((item) => {
                    return <option value={item.ERTeamID}>{item.Name}</option>;
                  })}
                </select>
                <br></br>
                <br></br>
                <div className="div2equal">
                  <button
                    className="ubtn"
                    onClick={(e) => {
                      updateStatus(
                        props.item.ID,
                        rfselectERTeam.current.value,
                        selectedStatus
                      );
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="li"
                    onClick={() => {
                      setSelectedStatus(null);
                      setERTeams(null);
                    }}
                  >
                    Close
                  </button>
                </div>
                {isLoading && <Loading />}
              </div>
            </div>
          )}

          {selectedStatus !== "In Progress" && selectedStatus !== "" && (
            <div className={popup}>
              <div className="conf">
                <h3>Incident Status</h3>
                <br />
                <p>{err}</p>
                <br />
                <p>
                  Confirm status update from {props.item.Status} to{" "}
                  {selectedStatus}
                </p>
                <br />
                <div className="div2equal">
                  <button
                    className="ubtn"
                    onClick={(e) => {
                      updateStatus(props.item.ID, null, selectedStatus);
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="li"
                    onClick={() => {
                      setSelectedStatus(null);
                    }}
                  >
                    Close
                  </button>
                </div>
                {isLoading && <Loading />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
