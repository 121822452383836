import MyMap from "../Map/Map";
import { useEffect, useState, useRef } from "react";
import "../../Styles/gis.scss";
import Header1 from "../Util/Header1";
import Navigation from "../Util/Navigation";
import MapGBV from "../Map/MapGBV";
import Analysis from "../IncidenceManagement/Analysis";

export default function GBVMap(props) {
  const [data, setData] = useState(null);
  const [tdata, setTData] = useState(null);
  const [total, setTotal] = useState(null); 
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch(`/api/reports/type/GBV/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setTData(data.results)
        setTotal(data.total)
      })
      .catch((e) => {
      });
  }, [offset])

  return (
    <div className="gis">
      <div className="bdy">
        <div className="map">
          <MapGBV setData={setData}/>
        </div>
        <Analysis type="GBV" data={tdata} total={total} offset={offset} setOffset={setOffset}/>
      </div>
    </div>
  );
}
