import { useEffect, useState, useRef, useLayoutEffect } from "react";
import CustomLineChart from "../Stats/CustomLineChart";
import CustomPieChart from "../Stats/CustomPieChart";
import CustomBarChart from "../Stats/CustomBarChart";
import Pagination from "../Util/Pagination";
import Chart from "./Chart";
import BottomTable from "./BottomTable";
import TopTable from "./TopTable";

export default function Analysis(props) {
  const [padding, setPadding] = useState([100, 700, 100, 100]);
  const [rStatus, setRStatus] = useState(null);
  const [expand, setExpand] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [drop, setDrop] = useState(false);
  const [eventId, setEventId] = useState(null);
  const colors = ["red", "orange", "blue", "purple", "black"];
  const [erteams, setErteams] = useState();
  const [performanceStats, setPerformanceStats] = useState(null);

  useEffect(() => {
    fetch(`/api/reports/performance/erteams`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        console.log(data);
        // let performance = [];

        // performance = data?.map((item) => {
        //   const per = {};
        //   per.name = item.Name;
        //   per.value = parseInt(item.TotalResponses);
        //   return per;
        // });
        setErteams(data);
        setPerformanceStats(data);
      })
      .catch((err) => {});
  }, []);

  function scrollPages(offset) {
    props.setOffset(offset);
  }

  useEffect(() => {
    fetch(`/api/reports/status/type/${props.type}`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        let statuses = [];
        statuses = data?.result?.map((item) => {
          const status = {};
          status.name = item.Status;
          status.value = parseInt(item.count);
          return status;
        });
        setRStatus(statuses);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <div
        className={expand ? "expand_hidden" : "expand"}
        onClick={() => {
          setExpand(true);
        }}
      >
        <i className="fa fa-expand"></i>
      </div>
      {props.data && erteams && expand && (
        <div className={"r_analysis"}>
          <TopTable
            data={props.data}
            total={props.total}
            offset={props.offset}
            setOffset={props.setOffset}
            // setData={props.setData}
            setExpand={setExpand}
          />

          <div className="divs">
            <Chart
              title="Incidences Status"
              data={rStatus}
              colors={colors}
              legend={true}
            />

              <Chart
                title="ER Teams Performance"
                data={performanceStats}
                colors={colors}
                legend={true}
              />
         
          </div>
        </div>
      )}
    </div>
  );
}
