// import DataBox from "./DataBox";

import { useEffect, useLayoutEffect, useRef, useState } from "react";

export default function BlogPost(props) {
  const [blob, setBlob] = useState(null);
  const [width, setWidth] = useState(150);
  const [height, setHeight] = useState(250);
  const ref = useRef(null);

  useEffect(() => {
    if (props?.item?.Image) {
      const url = props?.item?.Image.substring(8, props?.item?.Image.length);
      fetch(`/api/uploads/${url}`)
        .then((res) => {
          console.log(res);
          if (res.ok) return res.blob();
          else throw Error("");
        })
        .then((blob) => {
          const src = URL.createObjectURL(blob);
          console.log(src);
          setBlob(src);
        })
        .catch((e) => {});
    }
  }, [props?.item?.Image]);

  return (
    <div
      className="newsBox"
      onClick={() => {
        window.location.href = `/news/${props.item.ID}`;
      }}
    >
      <img src={blob} alt={`${props.item.Title}`} />
      <div>
        <h4>
          {props.item.Title.length > 36
            ? props.item.Title.substring(0, 36) + "..."
            : props.item.Title +
              ": " +
              props.item.Description.substring(
                0,
                34 - props.item.Title.length
              ) +
              "..."}
        </h4>
        <p>{props.item.Type}</p>
        <h6>
          {props.item.createdAt.split("T")[0]} :{" "}
          {props.item.createdAt.split("T")[1].split(".")[0].substring(0, 5)}hrs
        </h6>
      </div>
    </div>
  );
}
